import Utility from "../../../shared/utility";
import AddFavourite from "../../utility/js/addFav";
import EncryptUtility from "../../utility/js/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp";
import carrierMasterComp from "../../company/carrier-master-comp.vue";
import carrierMasterService from "./service-level-service";
export default {
  data() {
    return {
      actionList: [],
      mainPage: "",
      favouriteList: [],
      pageUrl: "",
      subPage: "",
      showAdd: false,
      backSlash: true,
      refresh: true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      carrierSearch: "",
      carrierData: [],
      carrierCompCalled: false,
      singleCarrierDet: [],
      isActive: true,
      headerCarrier: [
        { text: "Project", value: "Project", class: "primary customwhite--text", width: "13%" },
        { text: "Business", value: "Business", class: "primary customwhite--text", width: "13%" },
        { text: "Friendly Name", value: "FriendlyName", class: "primary customwhite--text", width: "15%" },
        { text: "Account Number", value: "accountno", class: "primary customwhite--text", width: "15%" },
        { text: "Status", value: "IsActive", class: "primary customwhite--text", width: "8%" },
        { text: "Created By", value: "username", class: "primary customwhite--text", width: "12%" },
        { text: "Created Date", value: "CreatedDate", class: "primary customwhite--text", width: "14%" },
        { text: "Actions", value: "actions", class: "primary customwhite--text", width: "10%" },
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getServiceLevelData();
  },
  mounted() { },
  methods: {
    //Reset the screen
    resetFunction() {
      this.carrierSearch = "";
      this.carrierData = [];
      this.carrierCompCalled = false;
      this.singleCarrierDet = [];
      this.getServiceLevelData();
    },
    //Get Service Level Data
    async getServiceLevelData() {
      this.carrierData = [];
      let obj = {
        UserId: this.userId,
        pass: 1,
        account_id: 0,
        acct_name: "",
        carrier_key: 0,
        proj_key: 0,
        bu_key: 0,
        accountno: "",
        client_id: "",
        client_secret: "",
        ShipperAddress: "",
        BillToAddress: "",
        status: this.isActive,
      };
      let carrierLevelData = await carrierMasterService.postCarrierMaster("post", obj);
      if (carrierLevelData.message != "NA") {
        const newArr = carrierLevelData.map((obj) => {
          return {
            ...obj,
            CreatedDate: Utility.convertESTToLocal(obj.cdatetime),
          };
        });
        this.carrierData = newArr;
      }
    },
    //To close the componets
    close() {
      this.getServiceLevelData();
      this.name = "";
      this.carrierCompCalled = false;
    },
    //New Service button click event
    newCarrier() {
      this.carrierCompCalled = true;
      this.name = "newCarrier";
    },
    //Edit Service button click event
    editCarrier(item) {
      this.singleCarrierDet = [];
      this.singleCarrierDet = item;
      this.carrierCompCalled = true;
      this.name = "editCarrier";
    },
    // To check if the user has access access to that action or not
    actionSetter(action) {
      if (this.actionList !== null && this.actionList.length > 0) {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    //On Click of Favorite icon
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
    carrierMasterComp,
  },
};
